export const AboutArticles = () => import('../../components/about/AboutArticles.vue' /* webpackChunkName: "components/about-articles" */).then(c => wrapFunctional(c.default || c))
export const AboutBlog = () => import('../../components/about/AboutBlog.vue' /* webpackChunkName: "components/about-blog" */).then(c => wrapFunctional(c.default || c))
export const AboutCarousel = () => import('../../components/about/AboutCarousel.vue' /* webpackChunkName: "components/about-carousel" */).then(c => wrapFunctional(c.default || c))
export const AboutGetToKnow = () => import('../../components/about/AboutGetToKnow.vue' /* webpackChunkName: "components/about-get-to-know" */).then(c => wrapFunctional(c.default || c))
export const AboutLeadership = () => import('../../components/about/AboutLeadership.vue' /* webpackChunkName: "components/about-leadership" */).then(c => wrapFunctional(c.default || c))
export const AboutNav = () => import('../../components/about/AboutNav.vue' /* webpackChunkName: "components/about-nav" */).then(c => wrapFunctional(c.default || c))
export const AboutNewsReleases = () => import('../../components/about/AboutNewsReleases.vue' /* webpackChunkName: "components/about-news-releases" */).then(c => wrapFunctional(c.default || c))
export const AboutTimeline = () => import('../../components/about/AboutTimeline.vue' /* webpackChunkName: "components/about-timeline" */).then(c => wrapFunctional(c.default || c))
export const BrazeHomepageTopAlertContentCards = () => import('../../components/braze/BrazeHomepageTopAlertContentCards.vue' /* webpackChunkName: "components/braze-homepage-top-alert-content-cards" */).then(c => wrapFunctional(c.default || c))
export const Accordian = () => import('../../components/common/Accordian.vue' /* webpackChunkName: "components/accordian" */).then(c => wrapFunctional(c.default || c))
export const AppCallout = () => import('../../components/common/AppCallout.vue' /* webpackChunkName: "components/app-callout" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/common/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const Bagock = () => import('../../components/common/Bagock.vue' /* webpackChunkName: "components/bagock" */).then(c => wrapFunctional(c.default || c))
export const BagockCheckout = () => import('../../components/common/BagockCheckout.vue' /* webpackChunkName: "components/bagock-checkout" */).then(c => wrapFunctional(c.default || c))
export const BlokLink = () => import('../../components/common/BlokLink.vue' /* webpackChunkName: "components/blok-link" */).then(c => wrapFunctional(c.default || c))
export const BoConvention = () => import('../../components/common/BoConvention.vue' /* webpackChunkName: "components/bo-convention" */).then(c => wrapFunctional(c.default || c))
export const BoFavorites = () => import('../../components/common/BoFavorites.vue' /* webpackChunkName: "components/bo-favorites" */).then(c => wrapFunctional(c.default || c))
export const BocashHero = () => import('../../components/common/BocashHero.vue' /* webpackChunkName: "components/bocash-hero" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCallout = () => import('../../components/common/CategoryCallout.vue' /* webpackChunkName: "components/category-callout" */).then(c => wrapFunctional(c.default || c))
export const CategoryPreviewDeprecated = () => import('../../components/common/CategoryPreview-deprecated.vue' /* webpackChunkName: "components/category-preview-deprecated" */).then(c => wrapFunctional(c.default || c))
export const CateringExitPopup = () => import('../../components/common/CateringExitPopup.vue' /* webpackChunkName: "components/catering-exit-popup" */).then(c => wrapFunctional(c.default || c))
export const CateringFavorites = () => import('../../components/common/CateringFavorites.vue' /* webpackChunkName: "components/catering-favorites" */).then(c => wrapFunctional(c.default || c))
export const CateringHomeBanner = () => import('../../components/common/CateringHomeBanner.vue' /* webpackChunkName: "components/catering-home-banner" */).then(c => wrapFunctional(c.default || c))
export const CenteredText = () => import('../../components/common/CenteredText.vue' /* webpackChunkName: "components/centered-text" */).then(c => wrapFunctional(c.default || c))
export const CityHero = () => import('../../components/common/CityHero.vue' /* webpackChunkName: "components/city-hero" */).then(c => wrapFunctional(c.default || c))
export const Column = () => import('../../components/common/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c))
export const CommunityForm = () => import('../../components/common/CommunityForm.vue' /* webpackChunkName: "components/community-form" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/common/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const CulturePhotos = () => import('../../components/common/CulturePhotos.vue' /* webpackChunkName: "components/culture-photos" */).then(c => wrapFunctional(c.default || c))
export const Custom = () => import('../../components/common/Custom.vue' /* webpackChunkName: "components/custom" */).then(c => wrapFunctional(c.default || c))
export const DaleJr = () => import('../../components/common/DaleJr.vue' /* webpackChunkName: "components/dale-jr" */).then(c => wrapFunctional(c.default || c))
export const DownloadApp = () => import('../../components/common/DownloadApp.vue' /* webpackChunkName: "components/download-app" */).then(c => wrapFunctional(c.default || c))
export const EclubBanner = () => import('../../components/common/EclubBanner.vue' /* webpackChunkName: "components/eclub-banner" */).then(c => wrapFunctional(c.default || c))
export const ErrorModal = () => import('../../components/common/ErrorModal.vue' /* webpackChunkName: "components/error-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureText = () => import('../../components/common/FeatureText.vue' /* webpackChunkName: "components/feature-text" */).then(c => wrapFunctional(c.default || c))
export const FoldsOfHonor = () => import('../../components/common/FoldsOfHonor.vue' /* webpackChunkName: "components/folds-of-honor" */).then(c => wrapFunctional(c.default || c))
export const FormstackForm = () => import('../../components/common/FormstackForm.vue' /* webpackChunkName: "components/formstack-form" */).then(c => wrapFunctional(c.default || c))
export const FormstackIframe = () => import('../../components/common/FormstackIframe.vue' /* webpackChunkName: "components/formstack-iframe" */).then(c => wrapFunctional(c.default || c))
export const GiftCardInfo = () => import('../../components/common/GiftCardInfo.vue' /* webpackChunkName: "components/gift-card-info" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/common/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/common/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const HeroOld = () => import('../../components/common/HeroOld.vue' /* webpackChunkName: "components/hero-old" */).then(c => wrapFunctional(c.default || c))
export const HeroSection = () => import('../../components/common/HeroSection.vue' /* webpackChunkName: "components/hero-section" */).then(c => wrapFunctional(c.default || c))
export const HomepageSlider = () => import('../../components/common/HomepageSlider.vue' /* webpackChunkName: "components/homepage-slider" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/common/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const IconSection = () => import('../../components/common/IconSection.vue' /* webpackChunkName: "components/icon-section" */).then(c => wrapFunctional(c.default || c))
export const IframeBlock = () => import('../../components/common/IframeBlock.vue' /* webpackChunkName: "components/iframe-block" */).then(c => wrapFunctional(c.default || c))
export const IframeFormSection = () => import('../../components/common/IframeFormSection.vue' /* webpackChunkName: "components/iframe-form-section" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/common/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const InternetAgrees = () => import('../../components/common/InternetAgrees.vue' /* webpackChunkName: "components/internet-agrees" */).then(c => wrapFunctional(c.default || c))
export const LargeSlider = () => import('../../components/common/LargeSlider.vue' /* webpackChunkName: "components/large-slider" */).then(c => wrapFunctional(c.default || c))
export const Markdown = () => import('../../components/common/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c))
export const MemphisHero = () => import('../../components/common/MemphisHero.vue' /* webpackChunkName: "components/memphis-hero" */).then(c => wrapFunctional(c.default || c))
export const OffsetCards = () => import('../../components/common/OffsetCards.vue' /* webpackChunkName: "components/offset-cards" */).then(c => wrapFunctional(c.default || c))
export const OrderAgain = () => import('../../components/common/OrderAgain.vue' /* webpackChunkName: "components/order-again" */).then(c => wrapFunctional(c.default || c))
export const OrderAhead = () => import('../../components/common/OrderAhead.vue' /* webpackChunkName: "components/order-ahead" */).then(c => wrapFunctional(c.default || c))
export const OrderNowInApp = () => import('../../components/common/OrderNowInApp.vue' /* webpackChunkName: "components/order-now-in-app" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/common/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PageArticle = () => import('../../components/common/PageArticle.vue' /* webpackChunkName: "components/page-article" */).then(c => wrapFunctional(c.default || c))
export const PageText = () => import('../../components/common/PageText.vue' /* webpackChunkName: "components/page-text" */).then(c => wrapFunctional(c.default || c))
export const ProductCarousel = () => import('../../components/common/ProductCarousel.vue' /* webpackChunkName: "components/product-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductPreview = () => import('../../components/common/ProductPreview.vue' /* webpackChunkName: "components/product-preview" */).then(c => wrapFunctional(c.default || c))
export const PromoCallout = () => import('../../components/common/PromoCallout.vue' /* webpackChunkName: "components/promo-callout" */).then(c => wrapFunctional(c.default || c))
export const PromoImages = () => import('../../components/common/PromoImages.vue' /* webpackChunkName: "components/promo-images" */).then(c => wrapFunctional(c.default || c))
export const PromoModal = () => import('../../components/common/PromoModal.vue' /* webpackChunkName: "components/promo-modal" */).then(c => wrapFunctional(c.default || c))
export const PromoSlider = () => import('../../components/common/PromoSlider.vue' /* webpackChunkName: "components/promo-slider" */).then(c => wrapFunctional(c.default || c))
export const Quote = () => import('../../components/common/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c))
export const RedSplitColumn = () => import('../../components/common/RedSplitColumn.vue' /* webpackChunkName: "components/red-split-column" */).then(c => wrapFunctional(c.default || c))
export const RichTextRenderReplacer = () => import('../../components/common/RichTextRenderReplacer.vue' /* webpackChunkName: "components/rich-text-render-replacer" */).then(c => wrapFunctional(c.default || c))
export const RichTextRenderReplacerContent = () => import('../../components/common/RichTextRenderReplacerContent.vue' /* webpackChunkName: "components/rich-text-render-replacer-content" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/common/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Row = () => import('../../components/common/Row.vue' /* webpackChunkName: "components/row" */).then(c => wrapFunctional(c.default || c))
export const ScrollNavDeprecated = () => import('../../components/common/ScrollNav-deprecated.vue' /* webpackChunkName: "components/scroll-nav-deprecated" */).then(c => wrapFunctional(c.default || c))
export const SoCluckinGood = () => import('../../components/common/SoCluckinGood.vue' /* webpackChunkName: "components/so-cluckin-good" */).then(c => wrapFunctional(c.default || c))
export const SoCluckinGoodHero = () => import('../../components/common/SoCluckinGoodHero.vue' /* webpackChunkName: "components/so-cluckin-good-hero" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/common/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/common/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Teaser = () => import('../../components/common/Teaser.vue' /* webpackChunkName: "components/teaser" */).then(c => wrapFunctional(c.default || c))
export const TextImageSection = () => import('../../components/common/TextImageSection.vue' /* webpackChunkName: "components/text-image-section" */).then(c => wrapFunctional(c.default || c))
export const ThankYou = () => import('../../components/common/ThankYou.vue' /* webpackChunkName: "components/thank-you" */).then(c => wrapFunctional(c.default || c))
export const VideoSection = () => import('../../components/common/VideoSection.vue' /* webpackChunkName: "components/video-section" */).then(c => wrapFunctional(c.default || c))
export const VideoSlider = () => import('../../components/common/VideoSlider.vue' /* webpackChunkName: "components/video-slider" */).then(c => wrapFunctional(c.default || c))
export const WelcomeHero = () => import('../../components/common/WelcomeHero.vue' /* webpackChunkName: "components/welcome-hero" */).then(c => wrapFunctional(c.default || c))
export const WifiConnect = () => import('../../components/common/WifiConnect.vue' /* webpackChunkName: "components/wifi-connect" */).then(c => wrapFunctional(c.default || c))
export const Youtube = () => import('../../components/common/Youtube.vue' /* webpackChunkName: "components/youtube" */).then(c => wrapFunctional(c.default || c))
export const Cards = () => import('../../components/common/cards.vue' /* webpackChunkName: "components/cards" */).then(c => wrapFunctional(c.default || c))
export const Feature = () => import('../../components/common/feature.vue' /* webpackChunkName: "components/feature" */).then(c => wrapFunctional(c.default || c))
export const BirthdaySelect = () => import('../../components/forms/BirthdaySelect.vue' /* webpackChunkName: "components/birthday-select" */).then(c => wrapFunctional(c.default || c))
export const DynamicForm = () => import('../../components/forms/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const FavoriteItem = () => import('../../components/forms/FavoriteItem.vue' /* webpackChunkName: "components/favorite-item" */).then(c => wrapFunctional(c.default || c))
export const IframeForm = () => import('../../components/forms/IframeForm.vue' /* webpackChunkName: "components/iframe-form" */).then(c => wrapFunctional(c.default || c))
export const NuxtLinkButton = () => import('../../components/forms/NuxtLinkButton.vue' /* webpackChunkName: "components/nuxt-link-button" */).then(c => wrapFunctional(c.default || c))
export const PhoneField = () => import('../../components/forms/PhoneField.vue' /* webpackChunkName: "components/phone-field" */).then(c => wrapFunctional(c.default || c))
export const PromoCodeField = () => import('../../components/forms/PromoCodeField.vue' /* webpackChunkName: "components/promo-code-field" */).then(c => wrapFunctional(c.default || c))
export const RichCheckbox = () => import('../../components/forms/RichCheckbox.vue' /* webpackChunkName: "components/rich-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SingleSignOn = () => import('../../components/forms/SingleSignOn.vue' /* webpackChunkName: "components/single-sign-on" */).then(c => wrapFunctional(c.default || c))
export const StoreSelect = () => import('../../components/forms/StoreSelect.vue' /* webpackChunkName: "components/store-select" */).then(c => wrapFunctional(c.default || c))
export const ZipField = () => import('../../components/forms/ZipField.vue' /* webpackChunkName: "components/zip-field" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/layout/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/layout/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDeleteAddress = () => import('../../components/locations/ConfirmDeleteAddress.vue' /* webpackChunkName: "components/confirm-delete-address" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/locations/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const LocationGate = () => import('../../components/locations/LocationGate.vue' /* webpackChunkName: "components/location-gate" */).then(c => wrapFunctional(c.default || c))
export const LocationIndicator = () => import('../../components/locations/LocationIndicator.vue' /* webpackChunkName: "components/location-indicator" */).then(c => wrapFunctional(c.default || c))
export const LocationResult = () => import('../../components/locations/LocationResult.vue' /* webpackChunkName: "components/location-result" */).then(c => wrapFunctional(c.default || c))
export const LocationResultOld = () => import('../../components/locations/LocationResultOld.vue' /* webpackChunkName: "components/location-result-old" */).then(c => wrapFunctional(c.default || c))
export const LocationResultsCompact = () => import('../../components/locations/LocationResultsCompact.vue' /* webpackChunkName: "components/location-results-compact" */).then(c => wrapFunctional(c.default || c))
export const LocationResultsOld = () => import('../../components/locations/LocationResultsOld.vue' /* webpackChunkName: "components/location-results-old" */).then(c => wrapFunctional(c.default || c))
export const LocationSearch = () => import('../../components/locations/LocationSearch.vue' /* webpackChunkName: "components/location-search" */).then(c => wrapFunctional(c.default || c))
export const Locations = () => import('../../components/locations/Locations.vue' /* webpackChunkName: "components/locations" */).then(c => wrapFunctional(c.default || c))
export const RadarMap = () => import('../../components/locations/RadarMap.vue' /* webpackChunkName: "components/radar-map" */).then(c => wrapFunctional(c.default || c))
export const RadarMapSingle = () => import('../../components/locations/RadarMapSingle.vue' /* webpackChunkName: "components/radar-map-single" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/order/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartOffers = () => import('../../components/order/CartOffers.vue' /* webpackChunkName: "components/cart-offers" */).then(c => wrapFunctional(c.default || c))
export const CartPromo = () => import('../../components/order/CartPromo.vue' /* webpackChunkName: "components/cart-promo" */).then(c => wrapFunctional(c.default || c))
export const CartUpsells = () => import('../../components/order/CartUpsells.vue' /* webpackChunkName: "components/cart-upsells" */).then(c => wrapFunctional(c.default || c))
export const Categories = () => import('../../components/order/Categories.vue' /* webpackChunkName: "components/categories" */).then(c => wrapFunctional(c.default || c))
export const ModifierSelections = () => import('../../components/order/ModifierSelections.vue' /* webpackChunkName: "components/modifier-selections" */).then(c => wrapFunctional(c.default || c))
export const OrderStatus = () => import('../../components/order/OrderStatus.vue' /* webpackChunkName: "components/order-status" */).then(c => wrapFunctional(c.default || c))
export const ProductModifiers = () => import('../../components/order/ProductModifiers.vue' /* webpackChunkName: "components/product-modifiers" */).then(c => wrapFunctional(c.default || c))
export const RoundUp = () => import('../../components/order/RoundUp.vue' /* webpackChunkName: "components/round-up" */).then(c => wrapFunctional(c.default || c))
export const ScrollableMenu = () => import('../../components/order/ScrollableMenu.vue' /* webpackChunkName: "components/scrollable-menu" */).then(c => wrapFunctional(c.default || c))
export const Upsells = () => import('../../components/order/Upsells.vue' /* webpackChunkName: "components/upsells" */).then(c => wrapFunctional(c.default || c))
export const CateringFeaturedBanner = () => import('../../components/menu/CateringFeaturedBanner.vue' /* webpackChunkName: "components/catering-featured-banner" */).then(c => wrapFunctional(c.default || c))
export const FeaturedBanner = () => import('../../components/menu/FeaturedBanner.vue' /* webpackChunkName: "components/featured-banner" */).then(c => wrapFunctional(c.default || c))
export const MenuCategories = () => import('../../components/menu/MenuCategories.vue' /* webpackChunkName: "components/menu-categories" */).then(c => wrapFunctional(c.default || c))
export const MenuNav = () => import('../../components/menu/MenuNav.vue' /* webpackChunkName: "components/menu-nav" */).then(c => wrapFunctional(c.default || c))
export const Nutrition = () => import('../../components/menu/nutrition.vue' /* webpackChunkName: "components/nutrition" */).then(c => wrapFunctional(c.default || c))
export const ArticleGrid = () => import('../../components/news-and-community/ArticleGrid.vue' /* webpackChunkName: "components/article-grid" */).then(c => wrapFunctional(c.default || c))
export const ArticleSidebar = () => import('../../components/news-and-community/ArticleSidebar.vue' /* webpackChunkName: "components/article-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CallOut = () => import('../../components/news-and-community/CallOut.vue' /* webpackChunkName: "components/call-out" */).then(c => wrapFunctional(c.default || c))
export const FeaturedStory = () => import('../../components/news-and-community/FeaturedStory.vue' /* webpackChunkName: "components/featured-story" */).then(c => wrapFunctional(c.default || c))
export const Resources = () => import('../../components/news-and-community/Resources.vue' /* webpackChunkName: "components/resources" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/news-and-community/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const Story = () => import('../../components/news-and-community/Story.vue' /* webpackChunkName: "components/story" */).then(c => wrapFunctional(c.default || c))
export const CommunityStats = () => import('../../components/in-the-community/CommunityStats.vue' /* webpackChunkName: "components/community-stats" */).then(c => wrapFunctional(c.default || c))
export const CreditCardPayment = () => import('../../components/checkout/CreditCardPayment.vue' /* webpackChunkName: "components/credit-card-payment" */).then(c => wrapFunctional(c.default || c))
export const CustomerAddress = () => import('../../components/checkout/CustomerAddress.vue' /* webpackChunkName: "components/customer-address" */).then(c => wrapFunctional(c.default || c))
export const CustomerDetails = () => import('../../components/checkout/CustomerDetails.vue' /* webpackChunkName: "components/customer-details" */).then(c => wrapFunctional(c.default || c))
export const DoubleOrderConfirmation = () => import('../../components/checkout/DoubleOrderConfirmation.vue' /* webpackChunkName: "components/double-order-confirmation" */).then(c => wrapFunctional(c.default || c))
export const HandoffSummary = () => import('../../components/checkout/HandoffSummary.vue' /* webpackChunkName: "components/handoff-summary" */).then(c => wrapFunctional(c.default || c))
export const ItemSummary = () => import('../../components/checkout/ItemSummary.vue' /* webpackChunkName: "components/item-summary" */).then(c => wrapFunctional(c.default || c))
export const ModeButtons = () => import('../../components/checkout/ModeButtons.vue' /* webpackChunkName: "components/mode-buttons" */).then(c => wrapFunctional(c.default || c))
export const OrderDetails = () => import('../../components/checkout/OrderDetails.vue' /* webpackChunkName: "components/order-details" */).then(c => wrapFunctional(c.default || c))
export const OrderMode = () => import('../../components/checkout/OrderMode.vue' /* webpackChunkName: "components/order-mode" */).then(c => wrapFunctional(c.default || c))
export const OrderPromo = () => import('../../components/checkout/OrderPromo.vue' /* webpackChunkName: "components/order-promo" */).then(c => wrapFunctional(c.default || c))
export const OrderTime = () => import('../../components/checkout/OrderTime.vue' /* webpackChunkName: "components/order-time" */).then(c => wrapFunctional(c.default || c))
export const OrderTip = () => import('../../components/checkout/OrderTip.vue' /* webpackChunkName: "components/order-tip" */).then(c => wrapFunctional(c.default || c))
export const OrderTotals = () => import('../../components/checkout/OrderTotals.vue' /* webpackChunkName: "components/order-totals" */).then(c => wrapFunctional(c.default || c))
export const PaymentDetails = () => import('../../components/checkout/PaymentDetails.vue' /* webpackChunkName: "components/payment-details" */).then(c => wrapFunctional(c.default || c))
export const PickupDetails = () => import('../../components/checkout/PickupDetails.vue' /* webpackChunkName: "components/pickup-details" */).then(c => wrapFunctional(c.default || c))
export const StoredCardPaymentDeprecated = () => import('../../components/checkout/StoredCardPayment-deprecated.vue' /* webpackChunkName: "components/stored-card-payment-deprecated" */).then(c => wrapFunctional(c.default || c))
export const StoredCards = () => import('../../components/checkout/StoredCards.vue' /* webpackChunkName: "components/stored-cards" */).then(c => wrapFunctional(c.default || c))
export const TimeDetails = () => import('../../components/checkout/TimeDetails.vue' /* webpackChunkName: "components/time-details" */).then(c => wrapFunctional(c.default || c))
export const TipDetailsDeprecated = () => import('../../components/checkout/TipDetails-deprecated.vue' /* webpackChunkName: "components/tip-details-deprecated" */).then(c => wrapFunctional(c.default || c))
export const VehicleDetails = () => import('../../components/checkout/VehicleDetails.vue' /* webpackChunkName: "components/vehicle-details" */).then(c => wrapFunctional(c.default || c))
export const ChickenverseDetails = () => import('../../components/chickenverse/ChickenverseDetails.vue' /* webpackChunkName: "components/chickenverse-details" */).then(c => wrapFunctional(c.default || c))
export const ChickenverseHero = () => import('../../components/chickenverse/ChickenverseHero.vue' /* webpackChunkName: "components/chickenverse-hero" */).then(c => wrapFunctional(c.default || c))
export const ChickenverseSlider = () => import('../../components/chickenverse/ChickenverseSlider.vue' /* webpackChunkName: "components/chickenverse-slider" */).then(c => wrapFunctional(c.default || c))
export const ArticleFooter = () => import('../../components/foundation/ArticleFooter.vue' /* webpackChunkName: "components/article-footer" */).then(c => wrapFunctional(c.default || c))
export const DonateForm = () => import('../../components/foundation/DonateForm.vue' /* webpackChunkName: "components/donate-form" */).then(c => wrapFunctional(c.default || c))
export const FoundationContact = () => import('../../components/foundation/FoundationContact.vue' /* webpackChunkName: "components/foundation-contact" */).then(c => wrapFunctional(c.default || c))
export const FoundationLeadership = () => import('../../components/foundation/FoundationLeadership.vue' /* webpackChunkName: "components/foundation-leadership" */).then(c => wrapFunctional(c.default || c))
export const GolfClassic = () => import('../../components/foundation/GolfClassic.vue' /* webpackChunkName: "components/golf-classic" */).then(c => wrapFunctional(c.default || c))
export const BoJourneyBegins = () => import('../../components/bojourney/BoJourneyBegins.vue' /* webpackChunkName: "components/bo-journey-begins" */).then(c => wrapFunctional(c.default || c))
export const BoJourneyIntro = () => import('../../components/bojourney/BoJourneyIntro.vue' /* webpackChunkName: "components/bo-journey-intro" */).then(c => wrapFunctional(c.default || c))
export const BojourneySteps = () => import('../../components/bojourney/BojourneySteps.vue' /* webpackChunkName: "components/bojourney-steps" */).then(c => wrapFunctional(c.default || c))
export const FirstDayChecklist = () => import('../../components/bojourney/FirstDayChecklist.vue' /* webpackChunkName: "components/first-day-checklist" */).then(c => wrapFunctional(c.default || c))
export const GetToKnowBojangles = () => import('../../components/bojourney/GetToKnowBojangles.vue' /* webpackChunkName: "components/get-to-know-bojangles" */).then(c => wrapFunctional(c.default || c))
export const IconBo = () => import('../../components/bojourney/IconBo.vue' /* webpackChunkName: "components/icon-bo" */).then(c => wrapFunctional(c.default || c))
export const IconChecklist = () => import('../../components/bojourney/IconChecklist.vue' /* webpackChunkName: "components/icon-checklist" */).then(c => wrapFunctional(c.default || c))
export const IconChecklistActive = () => import('../../components/bojourney/IconChecklistActive.vue' /* webpackChunkName: "components/icon-checklist-active" */).then(c => wrapFunctional(c.default || c))
export const IconComplete = () => import('../../components/bojourney/IconComplete.vue' /* webpackChunkName: "components/icon-complete" */).then(c => wrapFunctional(c.default || c))
export const IconJourney = () => import('../../components/bojourney/IconJourney.vue' /* webpackChunkName: "components/icon-journey" */).then(c => wrapFunctional(c.default || c))
export const IconJourneyActive = () => import('../../components/bojourney/IconJourneyActive.vue' /* webpackChunkName: "components/icon-journey-active" */).then(c => wrapFunctional(c.default || c))
export const IconKnowActive = () => import('../../components/bojourney/IconKnowActive.vue' /* webpackChunkName: "components/icon-know-active" */).then(c => wrapFunctional(c.default || c))
export const IconNext = () => import('../../components/bojourney/IconNext.vue' /* webpackChunkName: "components/icon-next" */).then(c => wrapFunctional(c.default || c))
export const IconPrev = () => import('../../components/bojourney/IconPrev.vue' /* webpackChunkName: "components/icon-prev" */).then(c => wrapFunctional(c.default || c))
export const ItsBoTime = () => import('../../components/bojourney/ItsBoTime.vue' /* webpackChunkName: "components/its-bo-time" */).then(c => wrapFunctional(c.default || c))
export const ChickenSandwichHero = () => import('../../components/chickensandwich/ChickenSandwichHero.vue' /* webpackChunkName: "components/chicken-sandwich-hero" */).then(c => wrapFunctional(c.default || c))
export const ContactLink = () => import('../../components/chickensandwich/ContactLink.vue' /* webpackChunkName: "components/contact-link" */).then(c => wrapFunctional(c.default || c))
export const TryBoth = () => import('../../components/chickensandwich/TryBoth.vue' /* webpackChunkName: "components/try-both" */).then(c => wrapFunctional(c.default || c))
export const TwoSauces = () => import('../../components/chickensandwich/TwoSauces.vue' /* webpackChunkName: "components/two-sauces" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqArrow = () => import('../../components/update-account-faq/AccountFaqArrow.vue' /* webpackChunkName: "components/account-faq-arrow" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqHero = () => import('../../components/update-account-faq/AccountFaqHero.vue' /* webpackChunkName: "components/account-faq-hero" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqHowTo = () => import('../../components/update-account-faq/AccountFaqHowTo.vue' /* webpackChunkName: "components/account-faq-how-to" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqList = () => import('../../components/update-account-faq/AccountFaqList.vue' /* webpackChunkName: "components/account-faq-list" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqQuestionItem = () => import('../../components/update-account-faq/AccountFaqQuestionItem.vue' /* webpackChunkName: "components/account-faq-question-item" */).then(c => wrapFunctional(c.default || c))
export const AccountFaqSteps = () => import('../../components/update-account-faq/AccountFaqSteps.vue' /* webpackChunkName: "components/account-faq-steps" */).then(c => wrapFunctional(c.default || c))
export const ChickenRiceContent = () => import('../../components/chicken-rice-bowl/ChickenRiceContent.vue' /* webpackChunkName: "components/chicken-rice-content" */).then(c => wrapFunctional(c.default || c))
export const ChickenRiceHero = () => import('../../components/chicken-rice-bowl/ChickenRiceHero.vue' /* webpackChunkName: "components/chicken-rice-hero" */).then(c => wrapFunctional(c.default || c))
export const ChickenRiceVideo = () => import('../../components/chicken-rice-bowl/ChickenRiceVideo.vue' /* webpackChunkName: "components/chicken-rice-video" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsContact = () => import('../../components/bird-dogs/BirdDogsContact.vue' /* webpackChunkName: "components/bird-dogs-contact" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsHero = () => import('../../components/bird-dogs/BirdDogsHero.vue' /* webpackChunkName: "components/bird-dogs-hero" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsInfoSection = () => import('../../components/bird-dogs/BirdDogsInfoSection.vue' /* webpackChunkName: "components/bird-dogs-info-section" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsIngredients = () => import('../../components/bird-dogs/BirdDogsIngredients.vue' /* webpackChunkName: "components/bird-dogs-ingredients" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsOrderNow = () => import('../../components/bird-dogs/BirdDogsOrderNow.vue' /* webpackChunkName: "components/bird-dogs-order-now" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsSeparator = () => import('../../components/bird-dogs/BirdDogsSeparator.vue' /* webpackChunkName: "components/bird-dogs-separator" */).then(c => wrapFunctional(c.default || c))
export const BirdDogsVideo = () => import('../../components/bird-dogs/BirdDogsVideo.vue' /* webpackChunkName: "components/bird-dogs-video" */).then(c => wrapFunctional(c.default || c))
export const BoBerryFeatures = () => import('../../components/sausage-bo-berry-biscuit/BoBerryFeatures.vue' /* webpackChunkName: "components/bo-berry-features" */).then(c => wrapFunctional(c.default || c))
export const BoBerryHero = () => import('../../components/sausage-bo-berry-biscuit/BoBerryHero.vue' /* webpackChunkName: "components/bo-berry-hero" */).then(c => wrapFunctional(c.default || c))
export const BoBerryOrder = () => import('../../components/sausage-bo-berry-biscuit/BoBerryOrder.vue' /* webpackChunkName: "components/bo-berry-order" */).then(c => wrapFunctional(c.default || c))
export const WWELogo = () => import('../../components/wwe/WWELogo.vue' /* webpackChunkName: "components/w-w-e-logo" */).then(c => wrapFunctional(c.default || c))
export const WweCups = () => import('../../components/wwe/WweCups.vue' /* webpackChunkName: "components/wwe-cups" */).then(c => wrapFunctional(c.default || c))
export const WweHeader = () => import('../../components/wwe/WweHeader.vue' /* webpackChunkName: "components/wwe-header" */).then(c => wrapFunctional(c.default || c))
export const WweSlider = () => import('../../components/wwe/WweSlider.vue' /* webpackChunkName: "components/wwe-slider" */).then(c => wrapFunctional(c.default || c))
export const PeachHero = () => import('../../components/peach/PeachHero.vue' /* webpackChunkName: "components/peach-hero" */).then(c => wrapFunctional(c.default || c))
export const PeachSummerDessert = () => import('../../components/peach/PeachSummerDessert.vue' /* webpackChunkName: "components/peach-summer-dessert" */).then(c => wrapFunctional(c.default || c))
export const PeachTicker = () => import('../../components/peach/PeachTicker.vue' /* webpackChunkName: "components/peach-ticker" */).then(c => wrapFunctional(c.default || c))
export const BoWingsHero = () => import('../../components/bo-wings/BoWingsHero.vue' /* webpackChunkName: "components/bo-wings-hero" */).then(c => wrapFunctional(c.default || c))
export const BoWingsTwoCol = () => import('../../components/bo-wings/BoWingsTwoCol.vue' /* webpackChunkName: "components/bo-wings-two-col" */).then(c => wrapFunctional(c.default || c))
export const BoWingsTypes = () => import('../../components/bo-wings/BoWingsTypes.vue' /* webpackChunkName: "components/bo-wings-types" */).then(c => wrapFunctional(c.default || c))
export const AccountHistoryItem = () => import('../../components/account/AccountHistoryItem.vue' /* webpackChunkName: "components/account-history-item" */).then(c => wrapFunctional(c.default || c))
export const AccountItem = () => import('../../components/account/AccountItem.vue' /* webpackChunkName: "components/account-item" */).then(c => wrapFunctional(c.default || c))
export const CompleteProfile = () => import('../../components/account/CompleteProfile.vue' /* webpackChunkName: "components/complete-profile" */).then(c => wrapFunctional(c.default || c))
export const InboxMessage = () => import('../../components/account/InboxMessage.vue' /* webpackChunkName: "components/inbox-message" */).then(c => wrapFunctional(c.default || c))
export const OloAuth = () => import('../../components/account/OloAuth.vue' /* webpackChunkName: "components/olo-auth" */).then(c => wrapFunctional(c.default || c))
export const AddModifierIcon = () => import('../../components/icons/AddModifierIcon.vue' /* webpackChunkName: "components/add-modifier-icon" */).then(c => wrapFunctional(c.default || c))
export const BirthdayIcon = () => import('../../components/icons/BirthdayIcon.vue' /* webpackChunkName: "components/birthday-icon" */).then(c => wrapFunctional(c.default || c))
export const CheckboxChecked = () => import('../../components/icons/CheckboxChecked.vue' /* webpackChunkName: "components/checkbox-checked" */).then(c => wrapFunctional(c.default || c))
export const CheckboxUnchecked = () => import('../../components/icons/CheckboxUnchecked.vue' /* webpackChunkName: "components/checkbox-unchecked" */).then(c => wrapFunctional(c.default || c))
export const Coins = () => import('../../components/icons/Coins.vue' /* webpackChunkName: "components/coins" */).then(c => wrapFunctional(c.default || c))
export const EditModifierIcon = () => import('../../components/icons/EditModifierIcon.vue' /* webpackChunkName: "components/edit-modifier-icon" */).then(c => wrapFunctional(c.default || c))
export const Gift = () => import('../../components/icons/Gift.vue' /* webpackChunkName: "components/gift" */).then(c => wrapFunctional(c.default || c))
export const GreenCheckIcon = () => import('../../components/icons/GreenCheckIcon.vue' /* webpackChunkName: "components/green-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAdd = () => import('../../components/icons/IconAdd.vue' /* webpackChunkName: "components/icon-add" */).then(c => wrapFunctional(c.default || c))
export const IconAmex = () => import('../../components/icons/IconAmex.vue' /* webpackChunkName: "components/icon-amex" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft = () => import('../../components/icons/IconArrowLeft.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/icons/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconBell = () => import('../../components/icons/IconBell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c))
export const IconBellColor = () => import('../../components/icons/IconBellColor.vue' /* webpackChunkName: "components/icon-bell-color" */).then(c => wrapFunctional(c.default || c))
export const IconBoBox = () => import('../../components/icons/IconBoBox.vue' /* webpackChunkName: "components/icon-bo-box" */).then(c => wrapFunctional(c.default || c))
export const IconCalendar = () => import('../../components/icons/IconCalendar.vue' /* webpackChunkName: "components/icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconCard = () => import('../../components/icons/IconCard.vue' /* webpackChunkName: "components/icon-card" */).then(c => wrapFunctional(c.default || c))
export const IconCheckbox = () => import('../../components/icons/IconCheckbox.vue' /* webpackChunkName: "components/icon-checkbox" */).then(c => wrapFunctional(c.default || c))
export const IconCheckboxChecked = () => import('../../components/icons/IconCheckboxChecked.vue' /* webpackChunkName: "components/icon-checkbox-checked" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/icons/IconChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronLeft = () => import('../../components/icons/IconChevronLeft.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/icons/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconCircleArrowLeft = () => import('../../components/icons/IconCircleArrowLeft.vue' /* webpackChunkName: "components/icon-circle-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconCircleArrowRight = () => import('../../components/icons/IconCircleArrowRight.vue' /* webpackChunkName: "components/icon-circle-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconClock = () => import('../../components/icons/IconClock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c))
export const IconClockColor = () => import('../../components/icons/IconClockColor.vue' /* webpackChunkName: "components/icon-clock-color" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconCloseMenu = () => import('../../components/icons/IconCloseMenu.vue' /* webpackChunkName: "components/icon-close-menu" */).then(c => wrapFunctional(c.default || c))
export const IconCog = () => import('../../components/icons/IconCog.vue' /* webpackChunkName: "components/icon-cog" */).then(c => wrapFunctional(c.default || c))
export const IconConfetti = () => import('../../components/icons/IconConfetti.vue' /* webpackChunkName: "components/icon-confetti" */).then(c => wrapFunctional(c.default || c))
export const IconDarkMapPin = () => import('../../components/icons/IconDarkMapPin.vue' /* webpackChunkName: "components/icon-dark-map-pin" */).then(c => wrapFunctional(c.default || c))
export const IconDirections = () => import('../../components/icons/IconDirections.vue' /* webpackChunkName: "components/icon-directions" */).then(c => wrapFunctional(c.default || c))
export const IconDiscover = () => import('../../components/icons/IconDiscover.vue' /* webpackChunkName: "components/icon-discover" */).then(c => wrapFunctional(c.default || c))
export const IconEdit = () => import('../../components/icons/IconEdit.vue' /* webpackChunkName: "components/icon-edit" */).then(c => wrapFunctional(c.default || c))
export const IconExternalLink = () => import('../../components/icons/IconExternalLink.vue' /* webpackChunkName: "components/icon-external-link" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../components/icons/IconFilter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconHamburger = () => import('../../components/icons/IconHamburger.vue' /* webpackChunkName: "components/icon-hamburger" */).then(c => wrapFunctional(c.default || c))
export const IconHeart = () => import('../../components/icons/IconHeart.vue' /* webpackChunkName: "components/icon-heart" */).then(c => wrapFunctional(c.default || c))
export const IconHeartFilled = () => import('../../components/icons/IconHeartFilled.vue' /* webpackChunkName: "components/icon-heart-filled" */).then(c => wrapFunctional(c.default || c))
export const IconHistory = () => import('../../components/icons/IconHistory.vue' /* webpackChunkName: "components/icon-history" */).then(c => wrapFunctional(c.default || c))
export const IconInbox = () => import('../../components/icons/IconInbox.vue' /* webpackChunkName: "components/icon-inbox" */).then(c => wrapFunctional(c.default || c))
export const IconInboxWhite = () => import('../../components/icons/IconInboxWhite.vue' /* webpackChunkName: "components/icon-inbox-white" */).then(c => wrapFunctional(c.default || c))
export const IconInfo = () => import('../../components/icons/IconInfo.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconLocate = () => import('../../components/icons/IconLocate.vue' /* webpackChunkName: "components/icon-locate" */).then(c => wrapFunctional(c.default || c))
export const IconLocation = () => import('../../components/icons/IconLocation.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c))
export const IconLocationWhite = () => import('../../components/icons/IconLocationWhite.vue' /* webpackChunkName: "components/icon-location-white" */).then(c => wrapFunctional(c.default || c))
export const IconMapPin = () => import('../../components/icons/IconMapPin.vue' /* webpackChunkName: "components/icon-map-pin" */).then(c => wrapFunctional(c.default || c))
export const IconMarker = () => import('../../components/icons/IconMarker.vue' /* webpackChunkName: "components/icon-marker" */).then(c => wrapFunctional(c.default || c))
export const IconMastercard = () => import('../../components/icons/IconMastercard.vue' /* webpackChunkName: "components/icon-mastercard" */).then(c => wrapFunctional(c.default || c))
export const IconNoPhoto = () => import('../../components/icons/IconNoPhoto.vue' /* webpackChunkName: "components/icon-no-photo" */).then(c => wrapFunctional(c.default || c))
export const IconNone = () => import('../../components/icons/IconNone.vue' /* webpackChunkName: "components/icon-none" */).then(c => wrapFunctional(c.default || c))
export const IconOffers = () => import('../../components/icons/IconOffers.vue' /* webpackChunkName: "components/icon-offers" */).then(c => wrapFunctional(c.default || c))
export const IconPhone = () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconPhoneColor = () => import('../../components/icons/IconPhoneColor.vue' /* webpackChunkName: "components/icon-phone-color" */).then(c => wrapFunctional(c.default || c))
export const IconPinColor = () => import('../../components/icons/IconPinColor.vue' /* webpackChunkName: "components/icon-pin-color" */).then(c => wrapFunctional(c.default || c))
export const IconPromoCode = () => import('../../components/icons/IconPromoCode.vue' /* webpackChunkName: "components/icon-promo-code" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/icons/IconSearch.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconStar = () => import('../../components/icons/IconStar.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c))
export const IconStatusActive = () => import('../../components/icons/IconStatusActive.vue' /* webpackChunkName: "components/icon-status-active" */).then(c => wrapFunctional(c.default || c))
export const IconStatusComplete = () => import('../../components/icons/IconStatusComplete.vue' /* webpackChunkName: "components/icon-status-complete" */).then(c => wrapFunctional(c.default || c))
export const IconStatusInactive = () => import('../../components/icons/IconStatusInactive.vue' /* webpackChunkName: "components/icon-status-inactive" */).then(c => wrapFunctional(c.default || c))
export const IconSubtract = () => import('../../components/icons/IconSubtract.vue' /* webpackChunkName: "components/icon-subtract" */).then(c => wrapFunctional(c.default || c))
export const IconTrash = () => import('../../components/icons/IconTrash.vue' /* webpackChunkName: "components/icon-trash" */).then(c => wrapFunctional(c.default || c))
export const IconUser = () => import('../../components/icons/IconUser.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c))
export const IconUserWhite = () => import('../../components/icons/IconUserWhite.vue' /* webpackChunkName: "components/icon-user-white" */).then(c => wrapFunctional(c.default || c))
export const IconVisa = () => import('../../components/icons/IconVisa.vue' /* webpackChunkName: "components/icon-visa" */).then(c => wrapFunctional(c.default || c))
export const LocationIcon = () => import('../../components/icons/LocationIcon.vue' /* webpackChunkName: "components/location-icon" */).then(c => wrapFunctional(c.default || c))
export const ModifierAddedIcon = () => import('../../components/icons/ModifierAddedIcon.vue' /* webpackChunkName: "components/modifier-added-icon" */).then(c => wrapFunctional(c.default || c))
export const ModifierRemoveIcon = () => import('../../components/icons/ModifierRemoveIcon.vue' /* webpackChunkName: "components/modifier-remove-icon" */).then(c => wrapFunctional(c.default || c))
export const ModifierRemovedIcon = () => import('../../components/icons/ModifierRemovedIcon.vue' /* webpackChunkName: "components/modifier-removed-icon" */).then(c => wrapFunctional(c.default || c))
export const OurCommunityIcon = () => import('../../components/icons/OurCommunityIcon.vue' /* webpackChunkName: "components/our-community-icon" */).then(c => wrapFunctional(c.default || c))
export const OurFoodIcon = () => import('../../components/icons/OurFoodIcon.vue' /* webpackChunkName: "components/our-food-icon" */).then(c => wrapFunctional(c.default || c))
export const OurFranchiseIcon = () => import('../../components/icons/OurFranchiseIcon.vue' /* webpackChunkName: "components/our-franchise-icon" */).then(c => wrapFunctional(c.default || c))
export const OurPeopleIcon = () => import('../../components/icons/OurPeopleIcon.vue' /* webpackChunkName: "components/our-people-icon" */).then(c => wrapFunctional(c.default || c))
export const SearchIcon = () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/search-icon" */).then(c => wrapFunctional(c.default || c))
export const SummerFavoriteDessert = () => import('../../components/icons/SummerFavoriteDessert.vue' /* webpackChunkName: "components/summer-favorite-dessert" */).then(c => wrapFunctional(c.default || c))
export const SummerFavoriteDessertMobile = () => import('../../components/icons/SummerFavoriteDessertMobile.vue' /* webpackChunkName: "components/summer-favorite-dessert-mobile" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../components/icons/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const AppDownloadNow = () => import('../../components/app/AppDownloadNow.vue' /* webpackChunkName: "components/app-download-now" */).then(c => wrapFunctional(c.default || c))
export const AppFeatureOffers = () => import('../../components/app/AppFeatureOffers.vue' /* webpackChunkName: "components/app-feature-offers" */).then(c => wrapFunctional(c.default || c))
export const AppFeatures = () => import('../../components/app/AppFeatures.vue' /* webpackChunkName: "components/app-features" */).then(c => wrapFunctional(c.default || c))
export const AppHero = () => import('../../components/app/AppHero.vue' /* webpackChunkName: "components/app-hero" */).then(c => wrapFunctional(c.default || c))
export const AppHowItWorks = () => import('../../components/app/AppHowItWorks.vue' /* webpackChunkName: "components/app-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const BoTimeAnimation = () => import('../../components/animations/BoTimeAnimation.vue' /* webpackChunkName: "components/bo-time-animation" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
